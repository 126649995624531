import {backendApi} from "../../backend-api-sr"

export const OrderBacklogAnalysis = (() =>{
    const fetchMillFilter = ( async (subPath) => {
        var url = '/api/v3/kka/analysis/order-backlog-analysis/filter-mill'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const fetchMachTypeFilter = ( async (subPath) => {
        var url = '/api/v3/kka/analysis/order-backlog-analysis/filter-mach-type'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchJenisFilter = ( async (subPath) => {
        var url = '/api/v3/kka/analysis/order-backlog-analysis/filter-jenis'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchBarangJadiFilter = ( async (subPath) => {
        var url = '/api/v3/kka/analysis/order-backlog-analysis/filter-barang-jadi'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const fetchIndex = ( async (subPath, reqBody) => {
        var url = '/api/v3/kka/analysis/order-backlog-analysis'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchDetail = ( async (subPath, reqBody) => {
        var url = '/api/v3/kka/analysis/order-backlog-analysis/detail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {fetchMillFilter, fetchMachTypeFilter, fetchJenisFilter, fetchBarangJadiFilter, fetchIndex, fetchDetail};
})()